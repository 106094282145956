





































































































































import { ref, defineComponent, computed, watchEffect } from '@vue/composition-api';
import store, { useUserActions, useUserGetters } from '@/store';
import _ from 'lodash';
import { ActionTypes } from '@/store/modules/tools/actions';
import draggable from 'vuedraggable';

export default defineComponent({
  name: 'MDraggableAdks',
  components: {
    draggable
  },
  props: {
    value: {
      required: true,
      type: Object,
      default: () => {}
    },
    fetchProgram: {
      type: Function,
      default: () => {}
    },
    isDraft: {
      type: Boolean,
      required: false,
      default: false
    },
    documentType: {
      required: true,
      type: String,
      default: ''
    }
  },
  setup(props: any, ctx: any) {
    const { updateAdksPosition } = useUserActions(['updateAdksPosition']);
    const { getProgramDoc } = useUserGetters(['getProgramDoc']);
    const showPreInternshipSteps = ref(true);
    const lockedAdks = ['start', 'results'];
    const lockedCategories = ['project', 'signoff'];
    const showInternshipSteps = ref(true);
    const categoryRef = ref([]);
    const drag = ref(false);
    const categoryDrag = ref(false);
    const hiddenItems = ref([]);
    function capitalizeFirstLetter(string) {
      if (string.length > 15) {
        return `${string.slice(0, 12)}...`;
      }
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const docValue = computed({
      get: () => props.value,
      set: newPage => {
        ctx.emit('input', newPage);
      }
    });

    const categories = computed(() => {
      return getProgramDoc.value?.categories;
    });

    const adks = computed(() => {
      return getProgramDoc?.value?.adks;
    });

    const updateFilteredList = () => {
      if (adks.value?.length >= 0) {
        const categoryArray = [] as any;
        categories.value.forEach(element => {
          const adksArr = adks.value.filter(adk => adk.category === element.name);
          const filterAdks = _.sortBy(adksArr, 'position');
          categoryArray.push({
            categoryName: element.name,
            icon: element.icon,
            adks: filterAdks,
            isDraggable: !lockedCategories.includes(element?.name?.toLowerCase())
          });
        });
        categoryRef.value = categoryArray;
      }
    };

    const filteredList = computed(() => categoryRef.value);

    watchEffect(() => {
      updateFilteredList();
    });

    const dummyRef = ref(0);
    const onDragEnd = item => {
      categoryRef.value = [...categoryRef.value];
      dummyRef.value += 1;
    };
    const unDraggableAdks = ref(['start', 'results']);
    const adkItems = computed(() => {
      return _.sortBy(docValue.value.data.adks, 'position') || [];
    });
    const projectItems = ref(adkItems.value.filter(item => item.category === 'project'));
    const internshipItems = ref(adkItems.value.filter(item => item.category === 'internship'));

    const toggleCategory = catName => {
      if (hiddenItems.value.includes(catName)) {
        const index = hiddenItems.value.indexOf(catName);
        hiddenItems.value.splice(index, 1);
      } else {
        hiddenItems.value.push(catName);
      }
    };

    const isUpdatingAdks = ref(false);

    const updateAdks = async () => {
      const adksArray = [] as any;
      const categoriesArray = [] as any;
      filteredList.value.forEach((elem: any) => {
        const { adks, categoryName, icon } = elem;
        if (adks?.length > 0) {
          adks?.forEach((adk, index) => {
            adksArray.push({
              ...adk,
              category: elem.categoryName,
              position: index
            });
          });
        }
        categoriesArray.push({
          name: categoryName,
          icon
        });
      });
      isUpdatingAdks.value = true;
      const res = await updateAdksPosition({
        document_id: docValue.value.data._id.toString(),
        document_type: props?.isDraft ? 'ProgramRevision' : props?.documentType,
        adks: [...adksArray],
        categories: [...categoriesArray]
      });
      if (res) {
        docValue.value.data = {
          adks: [],
          ...getProgramDoc.value
        };
        isUpdatingAdks.value = false;
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          message: res?.message,
          type: 'success',
          isShowSnackbar: true
        });
        const programId = docValue.value.data.program_id || docValue.value.data._id;
        ctx.emit('toggle');
        ctx.root.$router.push({
          name: props?.documentType === 'ProgramSchema' ? 'templateSetup' : 'guide',
          params: { programId: programId.toString(), page: 0 }
        });
      }
    };

    const onMove = event => {
      const relatedContext = event.relatedContext?.element?.name;
      const relatedIndex = event.relatedContext?.index;
      const draggaedContext = event.draggedContext?.futureIndex;
      const draggedCurrentIndex = event.draggedContext?.index;
      if (relatedContext === 'start' && draggaedContext === 0) {
        return false;
      }
      if (relatedContext === 'results' && relatedIndex !== 0) {
        return false;
      }
      if (relatedContext === 'results' && draggedCurrentIndex < relatedIndex) {
        return false;
      }
      if (relatedContext === 'results' && draggaedContext === 1) {
        return false;
      }
      return true;
    };

    const categoryMove = event => {
      const relatedContext = event.relatedContext?.element?.categoryName;
      if (lockedCategories.includes(relatedContext?.toLowerCase())) {
        return false;
      }
      return true;
    };

    const isDraggable = item => {
      if (lockedAdks.includes(item.name)) {
        return false;
      }
      return true;
    };

    const openBuilder = () => {
      const { programId } = ctx.root.$route.params;
      ctx.root.$router.push({
        name: 'AddAdk',
        params: {
          schemaType: 'program',
          id: programId
        }
      });
    };

    return {
      drag,
      onDragEnd,
      filteredList,
      openBuilder,
      toggleCategory,
      showPreInternshipSteps,
      showInternshipSteps,
      projectItems,
      internshipItems,
      updateAdks,
      isUpdatingAdks,
      unDraggableAdks,
      capitalizeFirstLetter,
      isDraggable,
      onMove,
      categories,
      categoryMove,
      categoryDrag,
      hiddenItems
    };
  }
});
