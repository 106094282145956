var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-draggable-container"},[_c('draggable',{attrs:{"list":_vm.filteredList,"options":{ handle: '.categoryDragged', animation: 500 },"group":{ name: 'category' },"move":_vm.categoryMove},on:{"end":_vm.onDragEnd}},[_c('transition-group',{attrs:{"type":"transition","name":!_vm.drag ? 'flip-list' : null}},_vm._l((_vm.filteredList),function(category){return _c('v-stepper',{key:category.categoryName,staticClass:"guide-bar__stepper",class:category.isDraggable ? 'categoryDragged' : '',staticStyle:{"width":"255px"},attrs:{"vertical":"","draggable":category.isDraggable}},[_c('div',[_c('v-stepper-step',{key:category.categoryName,staticClass:"category guide-bar__timeline-category",attrs:{"color":"black","complete-icon":category.icon,"edit-icon":'$radioOff',"complete":true,"editable":false,"step":0},on:{"click":function($event){return _vm.toggleCategory(category.categoryName)}}},[_vm._v(_vm._s(category.categoryName))]),_c('draggable',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hiddenItems.includes(category.categoryName)),expression:"!hiddenItems.includes(category.categoryName)"}],staticClass:"timeline-list-wrapper",staticStyle:{"min-height":"10px"},attrs:{"options":{
              handle: '.drag',
              animation: 400,
              clone: false
            },"ghost-class":"ghost","tag":"ul","group":"items","list":category.adks,"move":_vm.onMove}},[_c('transition-group',{attrs:{"type":"transition","name":!_vm.drag ? 'flip-list' : null}},_vm._l((category.adks),function(item,index){return _c('li',{key:index,staticClass:"flex flex-row align-center justify-start pt-auto pb-auto",attrs:{"draggable":_vm.isDraggable(item)}},[_c('div',{staticClass:"mr-2"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-img',_vm._g({class:!_vm.unDraggableAdks.includes(item.name) ? 'drag' : '',staticStyle:{"cursor":"pointer"},attrs:{"width":"20px","height":"20px","src":"/img/icons/drag.svg"}},on))]}}],null,true)},[_c('span',[_vm._v("Reorder")])])],1),(item)?_c('div',{staticClass:"ma-2"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('h5',_vm._g({staticClass:"grey--text",staticStyle:{"text-align":"left"}},on),[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(item.name))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])],1):_vm._e(),_c('div',{staticStyle:{"margin-left":"auto"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({attrs:{"slot":"label"},slot:"label"},on),[_c('v-checkbox',_vm._g({attrs:{"disabled":_vm.unDraggableAdks.includes(item.name),"value":item.status},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}},on))],1)]}}],null,true)},[(item.status)?_c('span',[_vm._v("Remove")]):_c('span',[_vm._v("Add")])])],1)])}),0)],1)],1)])}),1)],1),_c('div',[_c('v-row',{staticClass:"d-flex justify-center publishedBtn mt-2"},[_c('v-col',[_c('v-btn',{staticClass:"font-weight-bold white--text",attrs:{"color":"red","depressed":"","rounded":"","x-large":""},on:{"click":function($event){return _vm.$emit('toggle')}}},[_vm._v("Cancel")])],1),_c('v-col',[_c('v-btn',{staticClass:"font-weight-bold white--text",attrs:{"depressed":"","rounded":"","color":"green","x-large":"","loading":_vm.isUpdatingAdks},on:{"click":function($event){return _vm.updateAdks()}}},[_vm._v("Save")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }